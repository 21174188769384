<template>
  <reversi/>
</template>

<script>
import reversi from "./components/reversi.vue"

export default {
  name: 'App',
  components:{
    reversi
  }  
}
</script>



